import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, SearchInput, DateInput, DateField, NumberInput, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField } from "react-admin";

const customerFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Name" source="name" />,
];

// detect using: ListGuesser
export const CustomerList = (props: any) => (
    <List {...props} filters={customerFilters} >
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="category" />
            <TextField source="phone" />
            <TextField source="email" />
            <TextField source="description" />
            <TextField source="createdAt" />
            <TextField source="updatedAt" />
            <EditButton />
        </Datagrid>
    </List>
);

// view option
{/* <ArrayField source="zones">
<Datagrid bulkActionButtons={false}>
    <TextField source="name" />
    <TextField source="aph" />
</Datagrid>
</ArrayField> */}

// detect using: EditGuesser
export const CustomerEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <NumberInput source="category" />
            <ArrayInput source="zones">
                <SimpleFormIterator inline>
                    <TextInput source="name" helperText={false} />
                    <NumberInput source="aph" helperText={false} />
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="phone" />
            <TextInput source="email" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export const CustomerCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="name" />
            <NumberInput source="category" />
            <ArrayInput source="zones">
                <SimpleFormIterator inline>
                    <TextInput source="name" helperText={false} />
                    <NumberInput source="aph" helperText={false} />
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="phone" />
            <TextInput source="email" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);
