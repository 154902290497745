import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, SearchInput, 
    DateInput, DateField, NumberInput, NumberField, Show, SimpleShowLayout, RichTextField, 
    ShowButton, ReferenceInput, SelectInput, DateTimeInput, FileInput, FileField, ReferenceField, 
    ReferenceArrayInput, AutocompleteArrayInput, ArrayField, ReferenceArrayField, ReferenceArrayFieldView, WithListContext, SingleFieldList, ChipField, FieldTitle, 
    AutocompleteInput,
    FieldToggle,
    BooleanInput} from "react-admin";

const eventFilters = [
    <SearchInput source="q" alwaysOn />,
];

// detect using: ListGuesser
export const EventList = (props: any) => (
    <List {...props} filters={eventFilters} >
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="store_id" reference="stores">
                <TextField source="name" />
            </ReferenceField>
            {/* add auditors quantity field */}
            <TextField source="anagraphic_id" />
            <TextField source="pin" />
            <TextField source="auth" />
            <TextField source="valuePaid" />
            <TextField source="valueTaxes" />
            <TextField source="pc_operator_badge" />
            <TextField source="start_time" />
            <TextField source="end_time" />
            <TextField source="expire_date_enabled" />
            <TextField source="description" />
            <TextField source="createdAt" />
            <TextField source="updatedAt" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

const optionRenderer = (choice: { id: any; firstname: any; lastname: any; }) => 
        `${choice.id} - ${choice.firstname} ${choice.lastname}`;

// detect using: EditGuesser
export const EventEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="store_id" reference="stores">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput source="auditors" reference="auditors">
                <AutocompleteArrayInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <NumberInput source="anagraphic_id" />
            <FileInput source="attachments_info" multiple>
                <FileField source="src" title="title" />
            </FileInput>
            <TextInput source="pin" />
            <TextInput source="auth" />
            <NumberInput source="valuePaid" />
            <NumberInput source="valueTaxes" />
            <ReferenceArrayInput source="pc_operator_badge" reference="auditors">
                <AutocompleteInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <DateTimeInput source="start_time" />
            <DateTimeInput source="end_time" />
            <BooleanInput source="expire_date_enabled" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export const EventCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <ReferenceInput source="store_id" reference="stores">
                <SelectInput optionText="name" />
            </ReferenceInput>
            {/* <ReferenceArrayInput source="store_id" reference="stores">
                <AutocompleteArrayInput optionText="name" debounce={500} />
            </ReferenceArrayInput> */}
            <ReferenceArrayInput source="auditors" reference="auditors">
                <AutocompleteArrayInput 
                    // fullWidth
                    //optionText={record => record.firstname}
                    optionText={optionRenderer} />
            </ReferenceArrayInput>
            <NumberInput source="anagraphic_id" />
            <FileInput source="attachments_info" multiple>
                <FileField source="src" title="title" />
            </FileInput>
            <TextInput source="pin" />
            <TextInput source="auth" />
            <NumberInput source="valuePaid" />
            <NumberInput source="valueTaxes" />
            <ReferenceArrayInput source="pc_operator_badge" reference="auditors">
                <AutocompleteInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <DateTimeInput source="start_time" />
            <DateTimeInput source="end_time" />
            <BooleanInput source="expire_date_enabled" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);

export const EventShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="store_id" reference="stores">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField source="auditors" reference="auditors">
                {/* <SingleFieldList>
                    <ChipField source="id" />
                    <ChipField source="firstname" />
                </SingleFieldList> */}
            </ReferenceArrayField>
            <TextField source="anagraphic_id" />
            <FileField source="attachments_info[0].src" 
                    title="attachments_info[0].title" download="true" />
            <FileField source="link.url" title="link.title" download="true" />
            <TextField source="pin" />
            <TextField source="auth" />
            <TextField source="valuePaid" />
            <TextField source="valueTaxes" />
            <TextField source="pc_operator_badge" />
            <DateField source="start_time" />
            <DateField source="end_time" />
            <TextField source="expire_date_enabled" />
            <RichTextField source="description" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);
