import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, SearchInput, DateInput, DateField, Show, SimpleShowLayout, ShowButton, FileField } from "react-admin";

const softwareFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Platform" source="platform" />,
    <TextInput label="Description" source="description" />,
];

// detect using: ListGuesser
export const SoftwareList = (props: any) => (
    <List {...props} filters={softwareFilters} >
        <Datagrid>
            <TextField label="Id" source="id" />
            <TextField source="platform" />
            <TextField source="versionCode" />
            <TextField source="versionName" />
            <DateField source="releaseTime" />
            <TextField source="fileName" />
            <TextField source="isActive" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

// detect using: EditGuesser
export const SoftwareEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput label="Id" disabled source="id" />
            <TextInput source="platform" />
            <TextInput source="versionCode" />
            <TextInput source="versionName" />
            <TextInput source="description" />
            <DateInput source="releaseTime" />
            <TextInput source="fileName" />
            <TextInput source="md5" />
            <TextInput source="isActive" />
        </SimpleForm>
    </Edit>
);

export const SoftwareCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="platform" />
            <TextInput source="versionCode" />
            <TextInput source="versionName" />
            <TextInput source="description" />
            <DateInput source="releaseTime" />
            <TextInput source="fileName" />
            <TextInput source="md5" />
            <TextInput source="isActive" />
        </SimpleForm>
    </Create>
);

export const SoftwareShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="platform" />
            <TextField source="versionCode" />
            <TextField source="versionName" />
            <TextField source="description" />
            <DateField source="releaseTime" />
            <FileField source="link.url" title="link.title" download="true" />
            <TextField source="md5" />
            <TextField source="isActive" />
        </SimpleShowLayout>
    </Show>
);
